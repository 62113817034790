<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>View the record for: {{ user.first_name}} {{ user.last_name }}</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Details:</h3>
          <p>
            Email: {{ user.email_address }}<br />
          </p>
          <p v-if="user.address">
            Address: {{ user.address }}
          </p>
          <p v-if="user.city_guilds_enrolment_number">
            City &amp; Guilds No: {{ user.city_guilds_enrolment_number }}
          </p>
          <div v-html="user.notes"></div>
          <p>
            <a
              v-if="user.user_file_one"
              target="_blank"
              v-bind:href="user.user_file_one">{{ user.file_one_name }}
            </a><br />
            <a
              v-if="user.user_file_two"
              target="_blank"
              v-bind:href="user.user_file_two">{{ user.file_two_name }}
            </a><br />
            <a
              v-if="user.user_file_three"
              target="_blank"
              v-bind:href="user.user_file_three">{{ user.file_three_name }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-9">
              <h3>Notes about {{ user.first_name }} {{ user.last_name }}</h3>
            </div>
            <div class="cell small-12 medium-3 text-right">
              <button @click="addNote = true" class="button">Add Note</button>
            </div>
          </div>
          <div v-if="addNote" class="grid-x grid-padding-x">
            <div class="cell small-12">
              <Note :user="user.id" @noteAdded="noteAdded" />
            </div>
          </div>
          <div v-for="(note, index) in user.user_notes" :key="`un_${index}`">
            <p>{{ note.note }}</p>
            <p>
              Added {{ note.created | moment("dddd, MMMM Do YYYY") }} by
              {{ note.created_by_user.first_name }} {{ note.created_by_user.last_name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Evidence uploaded by {{ user.first_name }} {{ user.last_name }}</h3>
          <ul>
            <li v-for="(file, index) in user.files" v-bind:key="index">
              <router-link v-bind:to="`/view-file/${file.id}`">
                View {{ file.title }} || {{ file.sub_title }}
                <span v-if="file.status === 1"> | <strong>NEW</strong></span>
                <span v-if="file.status === 2"> | <strong>PENDING</strong></span>
                <span v-if="file.status === 3"> | <strong>PASSED</strong></span>
                <span v-if="file.status === 4"> | <strong>REFERRED</strong></span>
                <span>&nbsp;|&nbsp;Unread comments: {{ file.unreadComments }}</span>
              </router-link>
              <span v-if="file.status === 1">
                &nbsp;|&nbsp;<span v-on:click="deleteFile(file.id)" class="link">DELETE</span>
              </span>
              &nbsp;|&nbsp;
              Uploaded: {{ file.created | moment("dddd, MMMM Do YYYY") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Observation requests for {{ user.first_name }} {{ user.last_name }}</h3>
          <ul>
            <li v-for="(observation, index) in user.observation_requests" v-bind:key="index">
              <router-link v-bind:to="`/view-observation-request/${observation.id}`">
                View {{ observation.date | moment("dddd, MMMM Do YYYY") }}
                <span v-if="observation.accepted === false"> | <strong>NEW</strong></span>
                <span v-else>Accepted</span>
              </router-link>
              <span>&nbsp;|&nbsp;Unread comments: {{ observation.unreadComments }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Worksheets uploaded by {{ user.first_name }} {{ user.last_name }}</h3>
          <ul>
            <li v-for="(worksheet, index) in user.worksheets" v-bind:key="index">
              <router-link v-bind:to="`/view-worksheet/${worksheet.id}`">
                View {{ worksheet.title }}
                <span v-if="worksheet.status === 1"> | <strong>NEW</strong></span>
                <span v-if="worksheet.status === 2"> | <strong>PENDING</strong></span>
                <span v-if="worksheet.status === 3"> | <strong>PASSED</strong></span>
                <span v-if="worksheet.status === 4"> | <strong>REFERRED</strong></span>
              </router-link>
              <span v-if="worksheet.status === 1">
                &nbsp;|&nbsp;
                <span v-on:click="deleteWorksheet(worksheet.id)" class="link">
                  DELETE
                </span>
              </span>
              <span>&nbsp;|&nbsp;Unread comments: {{ worksheet.unreadComments }}</span>
              &nbsp;|&nbsp;
              Uploaded: {{ worksheet.created | moment("dddd, MMMM Do YYYY") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Note from '@/components/AddNote.vue';
import axios from '../axios';

export default {
  name: 'ViewUser',
  components: {
    Note,
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email_address: '',
        password: '',
        userlevel: 0,
        fileOneName: '',
        fileTwoName: '',
        fileThreeName: '',
        notes: [],
      },
      addNote: false,
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
  },
  methods: {
    getUser() {
      const userId = this.$route.params.id;
      axios.get(`/users/getUser/${userId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&course=${this.courseChosen}`)
        .then((response) => {
          this.user = response.data.user;
        });
    },
    noteAdded(value) {
      this.addNote = false;
      this.user.user_notes.push(value);
    },
    deleteWorksheet(worksheetId) {
      axios.get(`/worksheets/deleteWorksheet/${worksheetId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.user = response.data.user;
        });
    },
    deleteFile(fileId) {
      axios.get(`/files/deleteFile/${fileId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.user = response.data.user;
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
