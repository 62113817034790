<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <label>Add Note</label>
        <textarea v-model="note"></textarea>
        <button @click="save" class="button">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'AddNote',
  props: [
    'user',
  ],
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  data() {
    return {
      note: '',
    };
  },
  methods: {
    save() {
      const postData = {};
      postData.userId = this.user;
      postData.note = this.note;
      axios.post(`/userNotes/add.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&course=${this.courseChosen}`, postData)
        .then((response) => {
          this.$emit('noteAdded', response.data.response.note);
        });
    },
  },
};
</script>
